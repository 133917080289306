body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family : "Montserrat";
  font-size: 16px;
  color: #333;
}

select.form-control,
textarea.form-control,
input {
  font-size: 16px;
  margin: 10px;
}
input[type=file] {
  width: 100%;
}

.highlightCssEffect{
  animation-name: highlightCss !important;
    animation-duration: 4s !important;
}

@keyframes highlightCss {
  from {background-color: rgb(160, 160, 202);}
  to {background-color: rgb(219, 99, 159);}
}

.notificationhighlightCssEffect{
  animation-name: notfhighlightCss !important;
    animation-duration: 3s !important;
}

@keyframes notfhighlightCss {
  from {background-color: rgb(184, 184, 253);}
  to {background-color: rgb(245, 196, 220);}
}

/* notification badge */

.badge_css {
  position: absolute;
  top: -10px;
  right: -10px;
  line-height: 10px;
  padding: 5px;
  border-radius: 50%;
  background: red;
  color: white;
  font-weight: 600;
  font-family: lato;
}

.notification_header {
  cursor: pointer;
  margin: auto;
  color: white;
  text-decoration: none;
  position: relative;
}

/* Notification List  */
#notificationContainer 
{
background-color: #fff;
border: 1px solid rgba(100, 100, 100, .4);
-webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
overflow: visible;
position: absolute;
top: 50px;
margin-left: -170px;
width: 400px;
max-width: 400px;
right: 1%;
z-index: -1;
display: none; 
}

#notificationContainer:before {
content: '';
display: block;
position: absolute;
width: 0;
height: 0;
color: transparent;
border: 10px solid black;
border-color: transparent transparent white;
margin-top: -20px;
margin-left: 91%;
}
#notificationTitle{
font-weight: bold;
padding: 8px;
font-size: 13px;
color: #000;
text-align: center;
background-color: #ffffff;
position: fixed;
z-index: 1000;
width: 398px;
border-bottom: 1px solid #dddddd;
}
#notificationsBody{
padding: 33px 0px 0px 0px !important;
/* min-height:300px; */
max-height: 400px;
overflow: auto;
background:  #e8ecff;
}
#notificationFooter{
background-color: #e9eaed;
text-align: center;
font-weight: bold;
padding: 8px;
font-size: 12px;
border-top: 1px solid #dddddd;
}

.notf_list{
  display: flex;
  align-items: flex-start;
  padding: .55rem;
    margin-bottom: 2px;
    background: white;
}

.notf{
  display: block!important;
  justify-content: space-between!important;
  padding: 0rem 1rem;
  text-transform: capitalize;
}
.notf_title{
  color: #5d92f4!important;
  margin-bottom: .3125rem!important;
  text-align: left;
}
.notf_message{
  color: #727891!important;
  display: block;
  font-family: lato;
  text-align: left;
  font-size: 12px;
}

/* https://www.9lessons.info/2014/09/facebook-style-notification-popup-using.html */



/* Handeling the Css For React Select */
.custom_option{
    padding: 6px;
    cursor: pointer;
    font-family: lato;
    font-weight: 500;
    font-size: 14px;
}
.custom_option:hover{
  background-color: #60c1d829;
}

/* for loader in firefox */
.center_aligned{
  text-align: center;
  margin: 0px auto;
}