

.receipt_container {
    /* width: 595px;
    height: 841px; */
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid gray;
    font-family: "lato";
}

.receipt_main{
    padding: 50px;
}

.header{
    text-align: left;
    padding-bottom: 15px;
}

.h_divider{
    border: .8px solid #e6e6e6;
}

.fs14{
    font-size: 14px !important;
}
.logo_title{
    font-size: 10px;
    left: -113px;
    top: 3px;
    position: relative;
}

.transaction_date{
    text-align: left;
    padding: 20px 0px;
  
}

.transaction_date .transdatelabel{
    /* font-size: 12px; */
    /* font-weight: bold; */
    /* color: #000000; */
    width: 90px;
    height: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.12px;
    text-align: left;
    color: #000000;
}

.transaction_date .transdate{
    /* font-size: 15px;
    color: #000000;*/
    padding-top: 10px; 
    /* width: 111px; */
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #000000;
}

.table_container{
  padding: 16px 0px;  
}

.order_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: unset;
}

.headings{
    /* color:#00ad65;
    font-size: 12px;
    
    font-weight: bold; */
    /* width: 43px; */
    padding: 10px 0px;
  /* height: 11px; */
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.4; */
  /* letter-spacing: -0.1px; */
  text-align: left;
  color: #00ad65;
}


.order_info_cell{
    /* font-size: 15px;
    padding: 10px 0px;
    color: #000000; */
    /* width: 69px; */
    padding: 10px 0px;
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #000000;
}

.order_info_cell.highlighted{
    font-weight: bold;

}

.sender_info_cell{
    font-size: 15px;
    padding: 2px 0px;
    color: #000000;
    width: 50%;
    font-family: Roboto;
    text-transform: uppercase;
}

.sender_info_cell.highlighted{
    font-weight: bold;
    /* font-size: 16px;
    font-family: Roboto; */
}

.payment_details{
    text-align: left;
    padding: 16px 0px;
    font-family: Roboto;
    
}

.payment_details_row{
    display: flex;
    padding: 5px 0px;
}

.payment_details_row_title{
    width:40%;
    color: #000000;
    font-family: Roboto;
}
.payment_details_row_value{
    width:50%;
    padding-left: 8%;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Roboto;
}

.payment_details_row_value.amount{
    font-size: 18px;
    font-family: Roboto;
}

.subfooter{
    background: #e6e6e6;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
      /* width: 346px; */
  /* height: 13px; */
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: -0.11px;
  text-align: center;
  color: #000000;
}

.receipt_footer{
    height: 60px;
    background: #00ad65
}

.footer_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: unset;
}


.footer_text{
    color: #ffffff;
    text-align: center;
    /* font-size: 12px; */
    /* width: 74px;
    height: 11px; */
    font-family: Roboto;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 2.4; */
    /* letter-spacing: -0.1px; */
    text-align: left;
    color: #ffffff;
    width: 25%;
}