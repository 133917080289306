@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
* {
  box-sizing: border-box;
}

/* Demo */
main {
  text-align: center;
}

.img {
  margin-top: -10px
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  overflow-x: hidden;
}



.App .navbar {
  border-radius: 0px;
}

.App .navbar-brand {
  font-weight: bold;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

.outerContainer {
  font-size: 14px;
  letter-spacing: .5px;
}

.form-errors {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: red;
  text-align: right
}

.cursor-pointer {
  cursor: pointer;
}

.display-flex-margin-top-5 {
  display: flex;
  margin-top: 5;
}

.App-logo {
  width: 150px;
  padding: 5px;
}

.App-logo2 {
  width: 43px;
  margin-right: 10px;
  padding-right: 5px;
}

.drawerListItem {
  color: '#fff';
  letter-spacing: .5;
  font-size: 14;
}

.listitemText {
  font-size: 20px;
}

.tableContainer {
  padding: 5px;
}

.gridContainer {
  margin: 5px auto;
  padding-top: 5px;
  padding-bottom: 105px;
  /* height: 100vh; */
  overflow: auto;
  overflow-x: hidden;
}

.listUserContainer {
  margin: 20px auto;
}

.listRoleContainer {
  margin: 20px auto;
}

.listPermissionContainer {
  margin: 20px auto;
}

.AdduserContainer {
  margin: 20px auto;
}

.AddlabContainer {
  margin: 20px auto;
}

.AddroleContainer {
  margin: 20px auto;
}

.EdituserContainer {
  margin: 20px auto;
}

.inputClass {
  margin: 20px;
}

.tooltipClass {
  font-size: 14px;
}

.textWhite {
  color: white;
  padding: 3px;
}

.headerText {
  color: rgba(0, 0, 0, 0.54);
  /* font-size: 1.8125rem !important;  */
  font-weight: 300 !important;
  font-family: 'lato' !important;
  font-size: 20px !important;
  line-height: 1.13333em;
  margin-left: -.02em;
}

.simpleCardData {
  background: #fff !important;
  ;
  margin: 3 !important;
  ;
  text-align: center !important;
  ;
}

.textcyan {
  color: #0e1113;
  padding: 3px;
}

.pT3 {
  padding-top: 3px;
}

.pT10 {
  padding-top: 10px;
}

.patientCount {
  background-color: block;
  text-align: center;
  /* background-image: linear-gradient(to right bottom, #7783ff, #636dee, #4e57dc, #3840cb, #1b2ab9); */
  /* background-image: linear-gradient(to right bottom, #a0a3cb, #7d80be, #5c5db0, #3a3aa0, #08158e); */
  /* background-image: linear-gradient(to right bottom, #478e89, #25828b, #00748c, #00668c, #005687); */
  background-image: linear-gradient(to right bottom, #478e89, #25828b, #00748c, #00668c, #005687);
}

.overallDateTabBg {
  background-image: linear-gradient(to right, #667eea, #6e71d8, #7364c6, #7657b4, #764ba2);
}

.patientCountNumber {
  line-height: 2rem;
  display: block;
}

.mt22 {
  /* align-self: center; */
  margin-top: 22px ! important;
}

.textCenter {
  align-self: center;
  /* margin-top: 22px ! important; */
}

.text20pxbold {
  font-size: 20px;
  font-weight: bolder;
}

.circleTitle {
  /* margin-top: 5px !important; */
  /* background:#000; */
  width: 94%;
  border-radius: 5px;
  height: 28px;
  color: white;
  font-family: 'lato';
  display: inline-block;
}

.circleTitleText {
  float: left;
  height: 100%;
  color: white;
  padding-left: 5px;
  font-family: 'lato';
  padding-top: 5px;

}

.circlepercentageText {
  float: right;
  height: 100%;
  padding: 5px;
  color: black;
  background: #f0fb48;
  padding-right: 5px;
  font-size: 11px;
  font-family: 'lato';
  padding-top: 5px;
}

.circletext {
  /* background:#f0f; */
  color: white;
  margin-top: 12px;
  font-size: 24px;
  font-weight: 700;
  font-family: 'lato';
  display: block;
}

.circle {
  /* height: 80px;
  width: 80px;
  border-radius: 12px;
  margin-left: 2px; */
  height: 100px;
  width: 140px;
  border-radius: 5px;
  margin: 6px;
  display: inline-block;
  /* background: radial-gradient(circle at 100px 100px, #5cabff, #000); */
  background-image: linear-gradient(to right bottom, #478e89, #25828b, #00748c, #00668c, #005687);
}

.boxShadowNone>* {
  box-shadow: none;
}


.messagebox {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px;
  background: #61c3d8;
  text-align: left;
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  font-family: 'Montserrat' !important;
  position: relative;
}

.sb2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #61c3d8;
  border-top: 10px solid #61c3d8;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 6px;
}

.card1 {
  background: #fff;
  display: flex;
  border: 1px solid #ddd;
}

.image {
  float: left;
  background: #000;
  width: 25%;
  height: 150px
}

.content {
  float: left;
  height: 140px;
  width: 73%;
  overflow: hidden;
  padding: 5px;

}

.content h4 {
  margin: 5px 0;
}

.icon_card {
  color: black;
  padding-top: 13%;
  padding-left: 9%;
}

.font64 {
  font-size: 64px !important;
}

.text-black {
  color: #000;
}


.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 118px;
  height: 25px;
  padding: 3px;
  margin: 0 10px 10px 0;
  background: linear-gradient(to bottom, red, red 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;

  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 13px;
  font-weight: 600;
  font-family: arial;
  font-family: Lato;
  text-transform: uppercase;
  background: #DC143C;
  border-radius: inherit;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: white;

}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: #FFFFFF;

  opacity: 0;
}

.switch-input:checked~.switch-label {
  background: #228B22;
}

.switch-input:checked~.switch-label:before {
  opacity: 0;
}

.switch-input:checked~.switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 23px;
  height: 23px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;

}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;

}

.switch-input:checked~.switch-handle {
  left: 88px;

}

/* Transition
    ========================== */
.switch-label,
.switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

/* -----======-----------------------------=-=========----======--=-==-=--=====-==-==-=- */

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: end;
  margin-top: 42px;
}

.grid>article {
  min-height: 250px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.grid>article img {
  max-width: 100%;
}

.card-separator {
  /* width: 30px; */
  font-size: 40px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  /* position: absolute; */
  margin-top: -30px;
  width: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
}

.card-separator1 {
  /* width: 30px; */
  font-size: 40px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  /* position: absolute; */
  margin-top: 10px;
  width: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
  margin-bottom: -30px;
}

.card-separator {
  /* width: 30px; */
  font-size: 40px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  /* position: absolute; */
  margin-top: -30px;
  width: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
}

.text {
  padding: 0 20px 20px;
}

.text1 {
  padding: 0 5px 5px;
}

.text1>p {
  color: #000;
  font-size: 20px;
  font-family: Lato;
}

.text>p {
  color: #000;
  font-size: 20px;
  font-family: Lato;
}

.text>button {
  background: gray;
  border: 0;
  color: white;
  padding: 10px;
  width: 100%;
}

/* ---------------Cron Edit  */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  min-width: 200px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  /* right: 105%; */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptextAlignRight {
  right: 105%;
}

.tooltiptextAlignLeft {
  left: 105%;
}

/*----------------------------------------- patient journey */


/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;

  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #000000;
  top: 0;
  bottom: 0;
  left: 34%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  /* width: 50%; */
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  right: -17px;
  background-color: white;

  top: 22px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 34%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #61c3d8;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;

  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent #3e808e transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -8px;
  background: white;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #3e808e;
  position: relative;
  border-radius: 6px;
}

.msgTxt {
  font-size: 14px;
  color: white;
  font-family: 'lato';
}

.dateandMsgTagTxt {
  font-size: 14px;
  color: black;
  text-align: right;
  font-family: 'lato';
}

/*  - - - - - - - - - - - - - - - - - - -Switch For Sidebar  */
/* 
.gInput{
	height: 0;
	width: 0;
	visibility: hidden;
}

.globalSwitch_label {
	cursor: pointer;
	text-indent: 11px;
  height: 30px;
  color:white;
  padding-top: 4px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.globalSwitch_label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 5px;
	width: 40px;
	height: 25px;
	background: white;
	border-radius: 90px;
	transition: 0.3s;
}

.gInput:checked + label {
	background: #f34949;
}

.gInput:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.globalSwitch_label:active:after {
	width: 130px;
}
 */

.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  text-indent: -24px;
  border: 2px solid #999999;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.2s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "G";
  padding-left: 10px;
  background-color: #34A7C1;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "L";
  padding-right: 10px;
  background-color: #EEEEEE;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  height: 24px;
  width: 22px;
  margin: 6px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  /* right: 56px; */
  border: 2px solid #999999;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* ---------------------Second One */

.switchIconText {
  top: 7px;
  position: absolute;
  right: 15px;
  font-size: 10px;
}


/* -------------------------------------------- Select User Permission ----------------------------------------- */

.selectpermision_title {
  padding: 5px;
  color: white;
  height: 38px;
  /* border: 1px solid black; */
  font-family: lato;
  font-weight: 600;
  font-size: 18px;
}

.dbListblue {
  background: #10E7BD;
}

.dbListOrange {
  background: rgb(239, 156, 41);
}

.dbListPink {
  background: #e6008a;
}

.dbListDarkBlue {
  background: #193346;
}

.dbListdarkCyan {
  background: #0f5f3e;
}

.dbListdarkskyblue {
  background: #186971;
}

.Select-menu-outer {
  z-index: 1000;
  position: relative;
}

.updateBtndef {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.updateBtnFixed {
  background: #193b46;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  font-family: 'Lato';
  margin-right: 5px;
  color: #fff;
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-align: center;
  margin: ' 0 0 0 0';
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: '0.5s';
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: left;
  padding: 8px;
}

/* -----------------------------css--------------------------- */

.radio-pillbox {
  height: 2.5em;
  width: 40%;
  font-size: 1em;
  border: 0;
  border-radius: 75px;
  overflow: hidden;
  background: #fff;
  color: #000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.radio-pillbox radiogroup {
  height: 100%;
  width: 100%;
  display: flex;
}

.radio-pillbox radiogroup div {
  width: 100%;
  position: relative;
}

.radio-pillbox radiogroup div input {
  /* -webkit-appearance: inherit; */
  width: 100%;
  height: 100%;
  transition: background 300ms ease-out;
  margin: 0;
  outline: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: inset 5px 7px 3px 20px rgb(78, 198, 168);
}

.radio-pillbox radiogroup div input:checked {
  color: #0a4df5;
  border: none;
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 600;
  box-shadow: inset 5px 7px 3px 20px rgb(43, 102, 111);
}

.radio-pillbox radiogroup .first {
  border-radius: 75px 0 0 75px;
  border-left: none;
}

.radio-pillbox radiogroup .l ast {
  border-radius: 0 75px 75px 0;
}

.radio-pillbox radiogroup div label {
  position: absolute;
  top: 50%;
  /* top plus line-height centers text */
  line-height: 0;
  left: 0;
  font-weight: 600 !important;
  width: 100%;
  height: 100%;
  text-align: center;
}

.radio-pillbox input:focus {
  outline: 0;
}

.radio-pillbox label:after {
  content: "\2714";
  display: inline-block;
  transform: scale(0);
  transition: margin 250ms cubic-bezier(0.6, -0.28, 0.735, 0.045) 100ms, transform 250ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.radio-pillbox input:checked+label:after {
  margin-left: 12px;
  transform: scale(1.25);
  transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 250ms, margin 500ms, transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 250ms;
}



/*---------------------------- Css for Unhealthy Task -------------------------------*/
.mynav {
  position: absolute;
  top: 42px;
}

.mynav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #1bc2a2;
  overflow: inherit;
  text-align: left;
  max-width: inherit;
  width: inherit;
}

.mynav ul li {
  display: block;
  position: relative;
  float: left;
  background: #1bc2a2;
  width: 100%;
  max-width: 215px;
  border-bottom: 1px solid #6cb19b;

}


/* This hides the dropdowns */

.mynav .main-navigation>li ul {
  left: 212px;
  /* display: block; */
  top: 0;

}

.mynav li ul {
  display: none;
}

.mynav ul li span {
  display: block;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;

}

.mynav ul li span:hover {
  background: #2c3e50;
}

/* Display the dropdown */


.mynav li:hover>ul {
  display: block;
  position: absolute;
}

.mynav li:hover li {
  float: none;
}

.mynav li:hover span {
  background: #1bc2a2;
}

.mynav li:hover li span:hover {
  background: #2c3e50;
}

.mynav li ul li {
  border-top: 0;
}

/* Displays second level dropdowns to the right of the first level dropdown */


.mynav ul ul ul {
  left: 100%;
  top: 0;
  overflow: inherit;
  text-align: left;
}

/* Simple clearfix */



.mynav ul:before,
.mynav ul:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.mynav ul:after {
  clear: both;
}

/* -------------binod--------- */

/* width */
::-webkit-scrollbar {
  width: 10px;

}

::-webkit-scrollbar:hover {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #60c2d8 !important;
  width: 14px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e73e9c !important;
  width: 14px;
}

/* for the date picker */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.table-body {
  color: #2e3247;
  background: #e9eff1;
  /* margin-bottom: 30px; */
}

.add-icon {
  font-size: 22px !important;
  margin-right: 11px;
  color: #fff24f;
}

.search-input {
  padding: 10px;
  border: 1px solid !important;
  width: 90%;
  height: 40px;
  border-radius: 10px;
}

.search-input:focus {
  border: 1px solid !important;
}

.buisness-icon {
  float: left;
  color: #19da19;
  font-size: 30px;
}

.material-Icon {
  margin-left: 2px;
  color: #19da19;
  margin-right: 2px;
  font-size: 30px;
  margin-top: 10px;
}

.text-ellpses {
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 40px;
}

.market-val {
  font-size: 16px !important;
}

/* css for Payment container Supporting Invoice added @30-11-19 starts here */
.supportingimgCard {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 12px;
  box-shadow: 0px 0px 12px 6px rgba(110, 109, 110, 0.86);
}

/* css for Payment container Supporting Invoice added @30-11-19 Ends here */

/* css for DropDown React Selct  */

#reactSelectCustom {
  font-size: 15px;
  font-family: lato;
  font-weight: 500;
  letter-spacing: .6px;
}

#reactSelectCustom>div {
  border-color: hsl(0, 0%, 100%);
  border: 0px;
  /* transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.42); */
  /* border-top : 0px solid #fff !important; */
}

#reactSelectCustom :first-child:focus-within {

  border: 0px;
  /* transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  /* border-bottom: 0px solid rgba(0, 0, 0, 0.42);
  border-top : 0px solid #fff !important; */
}

/* css for tags in view transaction */
.translabelIcon {
  color: #60c1d8;
}

.translabeltag {
  min-width: 200px;
  max-width: 250px;
  /* color: #000; */
  color: white;
  text-align: center;
  font-family: "lato";
  font-weight: 500;
  font-size: 15px;
}

.labeltag {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 5px;
  border-radius: 6px;
  background: #60c1d8;
}

/* for edit transaction pop in Select */
.MuiPopover-root {
  z-index: 99999 !important;
}

.transaction-supporting-image {
  position: relative;
  display: inline-block;
  width: 150px;
  margin-left: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.transaction-supporting-image:hover .transaction-delete-icon {
  display: block;
}

.transaction-delete-icon {
  padding: 4px;
  cursor: pointer;
  padding-right: 7px;
  color: red;
  /* border: 1px solid #05073a; */
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.name-span {
  background-image: linear-gradient(dodgerblue, dodgerblue), linear-gradient(transparent, transparent);
  background-size: 0 5px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: all .2s ease-out;
}

.name-span:hover {
  /* The following line makes the underline only as wide as the text */
  /* background-size: calc(100% - 2em) 5px, auto; */
  background-size: 100% 1px, auto;
}

/* datepicker */
#date-picker-dialog-label {
  font-family: lato !important;
  font-weight: 500 !important;
}

#date-picker-dialog {
  font-family: lato !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}


.updateBtnFixedModal {
  background: #193b46;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  font-family: 'Lato';
  margin-right: 5px;
  color: #fff;
  position: absolute;
  right: 21%;
  bottom: 115px;
  text-align: center;
  margin: ' 0 0 0 0';
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: '0.5s';
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  cursor: pointer;
}

/* downlod sample and upload sample button */

.fixedLeftBtnContainer {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  bottom: 20px;
  left: 20%;
  z-index: 9;
}

.fixedLeftBtn {
  background: #50a1cf;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 20px 10px 20px;
  font-family: 'Lato';
  margin-right: 5px;
  color: #fff;
  position: absolute;
  left: 20px;
  bottom: 20px;
  text-align: center;
  margin: ' 0 0 0 0';
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: '0.5s';
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.swal2-shown {
  z-index: 999999 !important;
}

.MuiAutocomplete-popper {
  z-index: 999999 !important;
}


/* Hira */
.row-status {
  font-size: 12px;
  padding: 0 5px;
  line-height: 18px;
  border-radius: 2px;
  white-space: nowrap;
  display: inline-block;
  text-transform: capitalize;
}

.yellow {
  background-color: #FFCC00;
  color: #000;
}

.light-orange {
  color: #000;
  background-color: #fad6a5;
}

.purple {
  color: #fff;
  background-color: #c969b4;
}

.green {
  color: #fff;
  background-color: #4BB543;
}

.red {
  color: #fff;
  background-color: #c00;
}

.blue {
  color: #fff;
  background-color: rgb(73, 128, 234);
}

.na {
  color: #fff;
  background-color: #666;
}

.MuiGrid-spacing-xs-8>.MuiGrid-item {
  padding: 5px 32px !important;
}

.value-unit {
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 40px;
}

.value-unit span {
  font-size: 11px;
}

.value-unit span.n-a {
  font-size: 12px;
  color: #999;
}

.value-unit.status {
  background: #666;
  border-radius: 10px;
  color: #fff;
  line-height: 20px;
  padding: 0 5px;
  font-size: 12px;
}
.images-box {
  display: block;
}
.images-box h4 {
  margin: 30px 0 20px;
}
.image-thumbnails {
  display: flex;
  justify-content: flex-start;
}
.image-item {
  display: flex;
  width: 160px;
  height: 160px;
  border:solid 3px #999;
  border-radius:4px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 16px;
}
.image-item:last-child {
  margin-right: 0;
}
.image-item img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}
.image-item svg {
  position: absolute;
  top: 0;
  right: 0;
  fill: #ff0000;
  cursor: pointer;
}
.radio-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap; 
  flex-direction: row !important;
}
.radio-group label {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.cs-filter {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-top: 24px;
  margin-bottom: 20px;
}
.cs-filter .fa.fa-refresh {
  display: inline-block;
  padding: 4px 16px;
  font-size: 18px;
  color: #50a1cf;
  cursor: pointer;
  margin-right: 40px;
}
.cs-filter label span {
  font-size: 16px;
}
.text-link {
  display: inline-block;
  text-decoration: underline;
  color: rgb(63, 81, 181);
  font-weight: bold;
  cursor: pointer;
}
:root {
  --primary1Color: rgb(29, 74, 95);
  --primary2Color: rgb(255, 255, 255);
  --primary3Color: rgb(230, 230, 230);
  --textColor: rgb(26, 9, 9);
}



.app {
  height: 100%;
}


.class_ul  {
  padding: 0;
  margin: 0;
}

.class_li  {
  padding: 0;
  margin: 0;
  list-style: none;
}


.class_ul::-webkit-scrollbar { width: 0 !important }
.button.fa {
  cursor: pointer;
}

.app {
  background: rgb(29, 74, 95);
  background: var(--primary1Color);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app > * {
  flex: 1 1;
  display: flex;
  flex-direction: row;
}
.app .col-1 {
  z-index: 1;
}
.app .col-2 {
  flex: 1 1;
}
.app .col-2 .content {
  text-align: center;
}

.vertical-navigation-component {
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  background: var(--primary2Color);
  overflow-x: hidden;
  width: 50px;
}
.vertical-navigation-component .nav-header h2 {
  white-space: nowrap;
  text-align: center;
}
.vertical-navigation-component.extended, .vertical-navigation-component .is-hover {
  min-width: 320px;
  background: #2e3247;

}
.vertical-navigation-component.extended .top-item {
  display: none;
}
.vertical-navigation-component.extended .active .sub-level {
  display: block;
  position: relative;
}
.vertical-navigation-component.extended .is-hover:not(.active) .sub-level, .vertical-navigation-component.collapsed .is-hover .sub-level {
  display: contents;
  position: fixed;
  width: 320px;
  top: 0;
  left: 0;
}
.vertical-navigation-component .sub-level {
  display: none;
  flex-direction: column;
  background: rgb(230, 230, 230);
  background: var(--primary3Color);
}
.vertical-navigation-component .sub-level > * {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vertical-navigation-component .sub-level li {
  height: 40px;
}
.vertical-navigation-component .sub-level li:hover {
  background:#05073a;
  color: white;
}
.vertical-navigation-component .sub-level .top-item {
  border-bottom: 1px solid rgb(29, 74, 95);
  border-bottom: 1px solid var(--primary1Color);
  height: 42px;
  background: #f1f8fd;
}
.vertical-navigation-component .vertical-item-component {
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  /* opacity: .6; */
  cursor: pointer;
}
.vertical-navigation-component .vertical-item-component:hover, .vertical-navigation-component .vertical-item-component.active {
  opacity: 1;
  /* background: var(--primary3Color); */
}
.vertical-navigation-component .vertical-item-component .active {
  border-left: 5px #1991eb solid !important;
  padding-left: 0;
  background: rgb(230, 230, 230);
  background: var(--primary3Color);
}
.vertical-navigation-component .vertical-item-component .item {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vertical-navigation-component .vertical-item-component i {
  min-width: 51px;
  text-align: center;
}
.vertical-navigation-component .vertical-item-component ul {
  background: rgb(230, 230, 230);
  background: var(--primary3Color);
}
.vertical-navigation-component .vertical-item-component li {
  padding: 0 10px;
}


.sideBarIcon{
    width: 51px !important;
    padding: 1px 16px !important;
    font-size: 24px;
}

.item-name{
  font-size: 14px;
  font-weight: 500;
  color:#afb1b9;
  font-family: lato;
}


.receipt_container {
    /* width: 595px;
    height: 841px; */
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid gray;
    font-family: "lato";
}

.receipt_main{
    padding: 50px;
}

.header{
    text-align: left;
    padding-bottom: 15px;
}

.h_divider{
    border: .8px solid #e6e6e6;
}

.fs14{
    font-size: 14px !important;
}
.logo_title{
    font-size: 10px;
    left: -113px;
    top: 3px;
    position: relative;
}

.transaction_date{
    text-align: left;
    padding: 20px 0px;
  
}

.transaction_date .transdatelabel{
    /* font-size: 12px; */
    /* font-weight: bold; */
    /* color: #000000; */
    width: 90px;
    height: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.12px;
    text-align: left;
    color: #000000;
}

.transaction_date .transdate{
    /* font-size: 15px;
    color: #000000;*/
    padding-top: 10px; 
    /* width: 111px; */
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #000000;
}

.table_container{
  padding: 16px 0px;  
}

.order_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: unset;
}

.headings{
    /* color:#00ad65;
    font-size: 12px;
    
    font-weight: bold; */
    /* width: 43px; */
    padding: 10px 0px;
  /* height: 11px; */
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.4; */
  /* letter-spacing: -0.1px; */
  text-align: left;
  color: #00ad65;
}


.order_info_cell{
    /* font-size: 15px;
    padding: 10px 0px;
    color: #000000; */
    /* width: 69px; */
    padding: 10px 0px;
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.15px;
  text-align: left;
  color: #000000;
}

.order_info_cell.highlighted{
    font-weight: bold;

}

.sender_info_cell{
    font-size: 15px;
    padding: 2px 0px;
    color: #000000;
    width: 50%;
    font-family: Roboto;
    text-transform: uppercase;
}

.sender_info_cell.highlighted{
    font-weight: bold;
    /* font-size: 16px;
    font-family: Roboto; */
}

.payment_details{
    text-align: left;
    padding: 16px 0px;
    font-family: Roboto;
    
}

.payment_details_row{
    display: flex;
    padding: 5px 0px;
}

.payment_details_row_title{
    width:40%;
    color: #000000;
    font-family: Roboto;
}
.payment_details_row_value{
    width:50%;
    padding-left: 8%;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Roboto;
}

.payment_details_row_value.amount{
    font-size: 18px;
    font-family: Roboto;
}

.subfooter{
    background: #e6e6e6;
    line-height: 30px;
    vertical-align: middle;
    text-align: center;
      /* width: 346px; */
  /* height: 13px; */
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: -0.11px;
  text-align: center;
  color: #000000;
}

.receipt_footer{
    height: 60px;
    background: #00ad65
}

.footer_table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: unset;
}


.footer_text{
    color: #ffffff;
    text-align: center;
    /* font-size: 12px; */
    /* width: 74px;
    height: 11px; */
    font-family: Roboto;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 2.4; */
    /* letter-spacing: -0.1px; */
    text-align: left;
    color: #ffffff;
    width: 25%;
}
.orderList{
    height: 50px;
    background: #060a3b;
    color: #fff;
    padding: 13px;
    font-size: 20px;
    display: flex;
    border-radius: 8px;
}
.commodityName{
    width: 75%;
    display: flex;
    font-size: 18px;
    margin-top: 10px;
    margin-top: 20px;
}
.commodityDetail{
    margin-left: 30%;
    text-align: left;
    margin-top: 10px;
    /* padding: 5px; */
    font-size: 15px;
    font-weight: 700;
}
.detailCard{
    width: 96%;
    margin-left: 2%;
    font-family: lato;
}
.districtDiv{
    display: flex;
    height: 40px;
    padding: 8px;
    margin-top: 15px;
    background: #f5f5fa;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 14px !important;
    font-family: lato !important;
}
.name{
    margin-left: 10px;
    padding: 2px;
    font-size: 20px;
}





/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/* @font-face {
    font-family: Poppins-Regular;
    src: url('../fonts/poppins/Poppins-Regular.ttf'); 
  }
  
  @font-face {
    font-family: Poppins-Medium;
    src: url('../fonts/poppins/Poppins-Medium.ttf'); 
  }
  
  @font-face {
    font-family: Poppins-Bold;
    src: url('../fonts/poppins/Poppins-Bold.ttf'); 
  }
  
  @font-face {
    font-family: Poppins-SemiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.ttf'); 
  }
  
  @font-face {
    font-family: Montserrat-Bold;
    src: url('../fonts/montserrat/Montserrat-Bold.ttf'); 
  }
  
  @font-face {
    font-family: Montserrat-SemiBold;
    src: url('../fonts/montserrat/Montserrat-SemiBold.ttf'); 
  }
  
  @font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/montserrat/Montserrat-Regular.ttf'); 
  } */
  
  
  /*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
  
  * {
      margin: 0px; 
      padding: 0px; 
      box-sizing: border-box;
  }
  
  body, html {
      height: 100%;
      font-family:lato;
  }
  
  /*---------------------------------------------*/
  a {
      font-family: lato;
      font-size: 14px;
      line-height: 1.7;
      color: #666666;
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
    color: #6675df;
  }
  
  /*---------------------------------------------*/
  h1,h2,h3,h4,h5,h6 {
      margin: 0px;
  }
  
  p {
      /* font-family: Poppins-Regular;
      font-size: 14px; */
      line-height: 1.7;
      /* color: #666666;
      margin: 0px; */
  }
  
  /* ul, li {
      margin: 0px;
      list-style-type: none;
  } */
  
  
  /*---------------------------------------------*/
  input {
      outline: none;
      border: none;
  }
  
  textarea {
    outline: none;
    border: none;
  }
  
  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
#searchinput:focus {
    border-color: unset !important;
  }

  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; }
  input:focus::-moz-placeholder { color:transparent; }
  input:focus:-ms-input-placeholder { color:transparent; }
  
  textarea:focus::-webkit-input-placeholder { color:transparent; }
  textarea:focus:-moz-placeholder { color:transparent; }
  textarea:focus::-moz-placeholder { color:transparent; }
  textarea:focus:-ms-input-placeholder { color:transparent; }
  
  input::-webkit-input-placeholder { color: #999999;}
  input:-moz-placeholder { color: #999999;}
  input::-moz-placeholder { color: #999999;}
  input:-ms-input-placeholder { color: #999999;}
  
  textarea::-webkit-input-placeholder { color: #999999;}
  textarea:-moz-placeholder { color: #999999;}
  textarea::-moz-placeholder { color: #999999;}
  textarea:-ms-input-placeholder { color: #999999;}
  
  
  label {
    display: block;
    margin: 0;
  }
  
  /*---------------------------------------------*/
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  
  iframe {
      border: none !important;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ utility ]*/
  
  /*==================================================================
  [ Text ]*/
  .txt1 {
    font-family: lato;
    font-size: 13px;
    line-height: 1.4;
    color: #555555;
  }
  
  .txt2 {
    font-family: lato;
    font-size: 13px;
    line-height: 1.4;
    color: #999999;
  }
  
  
  /*==================================================================
  [ Size ]*/
  .size1 {
    width: 355px;
    max-width: 100%;
  }
  
  .size2 {
    width: calc(100% - 43px);
  }
  
  /*==================================================================
  [ Background ]*/
  .bg1 {background: #3b5998;}
  .bg2 {background: #1da1f2;}
  .bg3 {background: #cd201f;}
  
  
  /*//////////////////////////////////////////////////////////////////
  [ login ]*/
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;  
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
  }
  
  
  .wrap-login100 {
    width: 100%;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
  
  }
  
  /*==================================================================
  [ login more ]*/
  .login100-more {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/static/media/login.6f680be4.jpg);
    background-position: center;
    position: relative;
    z-index: 1;
  }
  
  .login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.1);
  }
  
  
  
  /*==================================================================
  [ Form ]*/
  
  .login100-form {
    width: 560px;
    min-height: 100vh;
    display: block;
    background-color: #f7f7f7;
    padding: 173px 55px 55px 55px;
  }
  
  .login100-form-title {
    width: 100%;
    display: block;
    font-family: lato;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
  }
  
  
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input100 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    height: 80px;
    position: relative;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin-bottom: 10px;
    border: none;
  }
  
  .label-input100 {
    font-family: lato;
    font-size: 18px;
    color: #999999;
    line-height: 1.2;
    text-align: left !important;
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
    padding-left: 24px;
    left: 0;
    top: 30px;
    transition: all 0.4s;
  }
  
  .input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-family: lato;
    font-size: 18px;
    color: #555555;
    line-height: 1.2;
    padding: 0 26px;
  }
  
  input.input100 {
    height: 100%;
    transition: all 0.4s;
  }
  
  /*---------------------------------------------*/
  
  .focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #6675df;
    border-radius: 10px;
  
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
    transform: scaleX(1.1) scaleY(1.3);
  }
  
  .input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  
  .eff-focus-selection {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  
  .input100:focus {
    height: 48px;
  }
  
  .input100:focus + .focus-input100 + .label-input100 {
    top: 14px;
    font-size: 13px;
  }
  
  .has-val {
    height: 48px !important;
  }
  
  .has-val + .focus-input100 + .label-input100 {
    top: 14px;
    font-size: 13px;
  }
  
  /*==================================================================
  [ Restyle Checkbox ]*/
  
  .input-checkbox100 {
    display: none;
  }
  
  .label-checkbox100 {
    font-family: lato;
    font-size: 13px;
    color: #999999;
    line-height: 1.4;
  
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;
  }
  
  .label-checkbox100::before {
    content: "\f00c";
    font-family: lato;
    font-size: 13px;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background: #fff;
    border: 1px solid #6675df;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-checkbox100:checked + .label-checkbox100::before {
    color: #6675df;
  }
  
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-login100-form-btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .login100-form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: #61c3d8;
  
    font-family: lato;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.4s;
  }
  
  .login100-form-btn:hover {
    background: #333333;
  }
  
  
  
  /*------------------------------------------------------------------
  [ Responsive ]*/
  
  @media (max-width: 992px) {
    .login100-form {
      width: 50%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .login100-more {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .login100-form {
      width: 100%;
    }
  
    .login100-more {
      display: none;
    }
  }
  
  @media (max-width: 576px) {
    .login100-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
    }
  }
  
  
  /*------------------------------------------------------------------
  [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  
  .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    z-index: 100;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
  
    font-family: lato;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s;
  }
  
  .alert-validate::after {
    content: "\f12a";
    font-family: lato;
    display: block;
    position: absolute;
    z-index: 110;
    color: #c80000;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
  }
  
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
  }
  
  @media (max-width: 992px) {
    .alert-validate::before {
      visibility: visible;
      opacity: 1;
    }
  }
  
  
  
  /*==================================================================
  [ Social ]*/
  .login100-form-social-item {
    width: 36px;
    height: 36px;
    font-size: 18px;
    color: #fff;
    border-radius: 50%;
  }
  
  .login100-form-social-item:hover {
    background: #333333;
    color: #fff;
  }
  .p-b-43 {padding-bottom: 43px;}

  /* --------------extras------------------- */

  /* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    /* transition: background-color 5000s ease-in-out 0s; */
    /* background-color: rgba(255, 255, 255, 0) !important; */
    /* -webkit-text-fill-color: #fff !important; */
    /* background: #1da1f2 !important;
}  */

#notfound {
    position: relative;
    height: 100vh;
  }
  
  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .notfound {
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    padding: 0px 15px;
  }
  
  .notfound .notfound-404 {
    position: relative;
    height: 150px;
    line-height: 150px;
    margin-bottom: 25px;
  }
  
  .notfound .notfound-404 h1 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 186px;
    font-weight: 900;
    margin: 0px;
    text-transform: uppercase;
    /* background: url('../img/text.png'); */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    background-size: cover;
    background-position: center;
  }
  
  .notfound h2 {
    font-family: 'Titillium Web', sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
  
  .notfound p {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  
  .notfound a {
    font-family: 'Titillium Web', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: #5c91fe;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    transition: 0.2s all;
  }
  
  .notfound a:hover {
    opacity: 0.8;
  }
  
  @media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 110px;
      line-height: 110px;
    }
    .notfound .notfound-404 h1 {
      font-size: 120px;
    }
  }
  
body {
  margin: 0;
  padding: 0;
  /* font-family: "Open Sans", sans-serif; */
  font-family : "Montserrat";
  font-size: 16px;
  color: #333;
}

select.form-control,
textarea.form-control,
input {
  font-size: 16px;
  margin: 10px;
}
input[type=file] {
  width: 100%;
}

.highlightCssEffect{
  animation-name: highlightCss !important;
    animation-duration: 4s !important;
}

@keyframes highlightCss {
  from {background-color: rgb(160, 160, 202);}
  to {background-color: rgb(219, 99, 159);}
}

.notificationhighlightCssEffect{
  animation-name: notfhighlightCss !important;
    animation-duration: 3s !important;
}

@keyframes notfhighlightCss {
  from {background-color: rgb(184, 184, 253);}
  to {background-color: rgb(245, 196, 220);}
}

/* notification badge */

.badge_css {
  position: absolute;
  top: -10px;
  right: -10px;
  line-height: 10px;
  padding: 5px;
  border-radius: 50%;
  background: red;
  color: white;
  font-weight: 600;
  font-family: lato;
}

.notification_header {
  cursor: pointer;
  margin: auto;
  color: white;
  text-decoration: none;
  position: relative;
}

/* Notification List  */
#notificationContainer 
{
background-color: #fff;
border: 1px solid rgba(100, 100, 100, .4);
-webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .25);
overflow: visible;
position: absolute;
top: 50px;
margin-left: -170px;
width: 400px;
max-width: 400px;
right: 1%;
z-index: -1;
display: none; 
}

#notificationContainer:before {
content: '';
display: block;
position: absolute;
width: 0;
height: 0;
color: transparent;
border: 10px solid black;
border-color: transparent transparent white;
margin-top: -20px;
margin-left: 91%;
}
#notificationTitle{
font-weight: bold;
padding: 8px;
font-size: 13px;
color: #000;
text-align: center;
background-color: #ffffff;
position: fixed;
z-index: 1000;
width: 398px;
border-bottom: 1px solid #dddddd;
}
#notificationsBody{
padding: 33px 0px 0px 0px !important;
/* min-height:300px; */
max-height: 400px;
overflow: auto;
background:  #e8ecff;
}
#notificationFooter{
background-color: #e9eaed;
text-align: center;
font-weight: bold;
padding: 8px;
font-size: 12px;
border-top: 1px solid #dddddd;
}

.notf_list{
  display: flex;
  align-items: flex-start;
  padding: .55rem;
    margin-bottom: 2px;
    background: white;
}

.notf{
  display: block!important;
  justify-content: space-between!important;
  padding: 0rem 1rem;
  text-transform: capitalize;
}
.notf_title{
  color: #5d92f4!important;
  margin-bottom: .3125rem!important;
  text-align: left;
}
.notf_message{
  color: #727891!important;
  display: block;
  font-family: lato;
  text-align: left;
  font-size: 12px;
}

/* https://www.9lessons.info/2014/09/facebook-style-notification-popup-using.html */



/* Handeling the Css For React Select */
.custom_option{
    padding: 6px;
    cursor: pointer;
    font-family: lato;
    font-weight: 500;
    font-size: 14px;
}
.custom_option:hover{
  background-color: #60c1d829;
}

/* for loader in firefox */
.center_aligned{
  text-align: center;
  margin: 0px auto;
}
