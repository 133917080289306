.orderList{
    height: 50px;
    background: #060a3b;
    color: #fff;
    padding: 13px;
    font-size: 20px;
    display: flex;
    border-radius: 8px;
}
.commodityName{
    width: 75%;
    display: flex;
    font-size: 18px;
    margin-top: 10px;
    margin-top: 20px;
}
.commodityDetail{
    margin-left: 30%;
    text-align: left;
    margin-top: 10px;
    /* padding: 5px; */
    font-size: 15px;
    font-weight: 700;
}
.detailCard{
    width: 96%;
    margin-left: 2%;
    font-family: lato;
}
.districtDiv{
    display: flex;
    height: 40px;
    padding: 8px;
    margin-top: 15px;
    background: #f5f5fa;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    font-size: 14px !important;
    font-family: lato !important;
}
.name{
    margin-left: 10px;
    padding: 2px;
    font-size: 20px;
}
